var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',[_c('v-card-text',{attrs:{"id":"users-edit__card-text"}},[_c('Fields',{attrs:{"id":"users_edit__fields","schema":{
        email: {
          type: 'email',
          label: 'E-Mail',
          fieldOptions: {
            readonly: true,
            clearable: false              
          },
          newDesign: true
        },
        roles: {
          type: 'roleSelect',
          label: 'Roles',
          fieldOptions: {
            disabled: !_vm.isAdmin && !_vm.environmentUser.canManageRoles,
            multi: true,
            noCreate: !_vm.isAdmin,
            enabledItems: !_vm.isAdmin && _vm.environmentUser.managedRoles
          },
          optional: true,
          newDesign: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),(_vm.isAdmin || _vm.environmentUser.canEditProfiles)?[(_vm.environment.serializedProfileSchema)?_c('h2',{staticClass:"headline mx-4 font-weight-bold"},[_vm._v("Perfil")]):_vm._e(),(_vm.environment.serializedProfileSchema)?_c('Fields',{attrs:{"schema":_vm.environment.serializedProfileSchema,"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user.profile),callback:function ($$v) {_vm.$set(_vm.user, "profile", $$v)},expression:"user.profile"}}):_vm._e()]:_vm._e(),(_vm.isAdmin)?[_c('h2',{staticClass:"headline mx-4 font-weight-bold"},[_vm._v("Permisos Adicionales")]),_c('Fields',{attrs:{"schema":{
          canAddUsers: {
            label: 'Puede añadir usuarios nuevos al ambiente',
            type: 'boolean',
            optional: true,
            newDesign: true
          },
          canRemoveUsers: {
            label: 'Puede eliminar usuarios del ambiente',
            type: 'boolean',
            optional: true,
            newDesign: true
          },
          canEditProfiles: {
            label: 'Puede editar perfiles de otros usuarios del ambiente',
            type: 'boolean',
            optional: true,
            newDesign: true
          },
          canManageRoles: {
            label: 'Puede administrar roles de otros usuarios del ambiente',
            type: 'boolean',
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-slide-y-transition',[(_vm.user.canManageRoles)?_c('Fields',{attrs:{"id":"users-edit__fields-admin-roles","schema":{
            managedRoles: {
              label: 'Roles administrables',
              description: 'Roles que se pueden ser asignados o eliminados por este usuario.',
              type: 'roleSelect',
              fieldOptions: {
                multi: true
              },
              optional: true,
              newDesign: true
            }
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):_vm._e()],1)]:_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":(!_vm.isAdmin && !_vm.environmentUser.canRemoveUsers) || (!_vm.isAdmin && _vm.environmentUser._id === _vm.user._id) || _vm.$apollo.loading || _vm.saving,"title":"Eliminar Usuario"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }